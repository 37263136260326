import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import Img from "gatsby-image"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  let featuredImgFluid = null
  if (post.frontmatter.featuredImage !== null && post.frontmatter.featuredImage !== undefined) {
    featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  }

  return (
    <Layout>
      <div>
        <h1>{post.frontmatter.title}</h1>
        {featuredImgFluid && 
          <Img fluid={featuredImgFluid} css={css`margin-bottom:30px; margin-top: 20px`}/>
        }
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`